import request from '../utils/request'
import baseUrl from './baseUrl'

export const vipList = (data) => request({
  url: baseUrl + '/userInfo/selectForBack',
  method: 'POST',
  data
})
export const changeLv = (data) => request({
  url: baseUrl + '/userInfo/modify',
  method: 'POST',
  data
})
export const vipDetail = (data) => request({
  url: baseUrl + '/userInfo/selectUserInfo',
  method: 'POST',
  data
})
export const updateUserBalance = (data) => request({
  url: baseUrl + '/userInfo/updateUserBalance',
  method: 'POST',
  data
})
export const updateUserIntegral = (data) => request({
  url: baseUrl + '/userInfo/updateUserIntegral',
  method: 'POST',
  data
})
export const balanceInfo = (data) => request({
  url: baseUrl + '/balanceInfo/selectByUser',
  method: 'POST',
  data
})
export const integralRecord = (data) => request({
  url: baseUrl + '/integralRecord/selectByUser',
  method: 'POST',
  data
})
export const selectOpinion = (data) => request({
  url: baseUrl + '/opinion/selectForBack',
  method: 'POST',
  data
})
export const opinionUpdate = (data) => request({
  url: baseUrl + '/opinion/update',
  method: 'POST',
  data
})
export const opinionDel = (data) => request({
  url: baseUrl + '/opinion/delete',
  method: 'POST',
  data
})
export const createForm = (data) => request({
  url: baseUrl + '/userInfo/exportExcel',
  method: 'POST',
  data,
  responseType: "blob"

})
export const getHobbyList = (data) => request({
  url: baseUrl + '/userHobby/userHobbyList',
  method: 'POST',
  data,


})
export const hobbyListAdd = (data) => request({
  url: baseUrl + '/userHobby/userHobbyAdd',
  method: 'POST',
  data,


})
export const hobbyListUpdata = (data) => request({
  url: baseUrl + '/userHobby/userHobbyUpdate',
  method: 'POST',
  data,
})
export const hobbyListDel = (data) => request({
  url: baseUrl + '/userHobby/userHobbyDelete',
  method: 'POST',
  data,


})
export const getIndustryList = (data) => request({
  url: baseUrl + '/userIndustry/userIndustryList',
  method: 'POST',
  data,


})
export const industryListAdd = (data) => request({
  url: baseUrl + '/userIndustry/userIndustryAdd',
  method: 'POST',
  data,


})
export const industryListUpdata = (data) => request({
  url: baseUrl + '/userIndustry/userIndustryUpdate',
  method: 'POST',
  data,


})
export const industryListDel = (data) => request({
  url: baseUrl + '/userIndustry/userIndustryDelete',
  method: 'POST',
  data,


})
export const getPointsInfo = (data) => request({
  url: baseUrl + '/integralSet/selectUserIntegralSet',
  method: 'POST',
  data,


})
export const pointsInfoSet = (data) => request({
  url: baseUrl + '/integralSet/modifyUserIntegralSet',
  method: 'POST',
  data,


})
export const receiveList = (data) => request({
  url: baseUrl + '/shareLevelSet/selectForMem',
  method: 'POST',
  data,


})
export const receiveListAdd = (data) => request({
  url: baseUrl + '/shareLevelSet/add',
  method: 'POST',
  data,
})
export const selectExcel = (data) => request({
  url: baseUrl + '/balanceInfo/selectExcel',
  method: 'POST',
  data,
  responseType: "blob"


})
export const disMore = (data) => request({
  url: baseUrl + '/balanceInfo/selectMore',
  method: 'POST',
  data,
})


// 推广海报列表查询
export const getPosterList= (data) => request({
  url: baseUrl + '/tjCode/selectForBack',
  method: 'POST',
  data,
})
//推广海报禁用/启用
export const updatePosterStatus= (data) => request({
  url: baseUrl + '/tjCode/update',
  method: 'POST',
  data,
})
//推广海报修改排序
export const modifyPosterDept= (data) => request({
  url: baseUrl + '/tjCode/modify',
  method: 'POST',
  data,
})
//推广海报编辑
export const modifyPoster= (data) => request({
  url: baseUrl + '/tjCode/add',
  method: 'POST',
  data,
})
//推广员
export const tgPeople= (data) => request({
  url: baseUrl + '/userInfo/selectTj',
  method: 'POST',
  data,
})
//推广员-导出
export const createFormPeople= (data) => request({
  url: baseUrl + '/userInfo/selectTjExport',
  method: 'POST',
  data,
  responseType:'blob'
})
//推广会员
export const tgPeopleUser= (data) => request({
  url: baseUrl + '/userInfo/selectForTj',
  method: 'POST',
  data,
})
//推广会员-导出
export const createFormPeopleUser= (data) => request({
  url: baseUrl + '/userInfo/selectForTjExport',
  method: 'POST',
  data,
  responseType:'blob'
})