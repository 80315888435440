<template>
  <div class="vipdetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>会员详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="手机号码">
        <div>
          <span>{{ vipdetail.userPhone }}</span>
        </div>
        <div>
          真实姓名:
          <span>{{ vipdetail.trueName }}</span>
        </div>
      </el-form-item>
      <el-form-item label="昵称">
        <div>
          <span>{{ vipdetail.nickName }}</span>
        </div>
        <div>
          所在省份:
          <span>{{ vipdetail.province }}</span>
        </div>
      </el-form-item>
      <el-form-item label="所在城市">
        <div>
          <span>{{ vipdetail.city }}</span>
        </div>
        <div>
          所在地区:
          <span>{{ vipdetail.areaName }}</span>
        </div>
      </el-form-item>
      <el-form-item label="头像">
        <div>
          <el-image
            style="width: 50px; height: 50px"
            :src="vipdetail.headImgUrl"
          ></el-image>
        </div>
        <div>
          性别:
          <span>{{ vipdetail.sex }}</span>
        </div>
      </el-form-item>
      <el-form-item label="注册时间">
        <div>
          <span>{{ vipdetail.addTime }}</span>
        </div>
        <div>
          总余额:
          <span>{{ vipdetail.balance }}</span>
        </div>
      </el-form-item>
      <el-form-item label="会员等级">
        <div>
          <span>{{ vipdetail.levelId }}</span>
        </div>
        <div>
          积分:
          <span>{{ vipdetail.integral }}</span>
        </div>
      </el-form-item>

      <el-form-item label="总积分">
        <div>
          <span>{{ vipdetail.totalIntegral }}</span>
        </div>
        <div>
          是否是合伙人:
          <span>{{ vipdetail.isPartner }}</span>
        </div>
      </el-form-item>
      <el-form-item label="分销收益">
        <div>
          <span>{{ vipdetail.profitMoney }}</span>
        </div>
        <div>
          总收益:
          <span>{{ vipdetail.totalMoney }}</span>
        </div>
      </el-form-item>
      <el-form-item label="待收益">
        <div>
          <span>{{ vipdetail.waitMoney }}</span>
        </div>
        <div>
          万小店店铺等级:
          <span>{{ vipdetail.profit_levelId }}</span>
        </div>
      </el-form-item>
      <el-form-item label="身份证">
        <div>
          <span>{{ vipdetail.idCard }}</span>
        </div>
        <div>
          会员号:
          <span>{{ vipdetail.no }}</span>
        </div>
      </el-form-item>
      <el-form-item label="地推人员ID">
        <div>
          <span>{{ $route.query.staffId }}</span>
        </div>
        <div>
          会员来源:
          <span>{{ vipdetail.strSource }}</span>
        </div>
      </el-form-item>

      <el-form-item label="活动标题" v-if="vipdetail.activityTitle">
        <div>
          <span>{{ vipdetail.activityTitle }}</span>
        </div>
        <div>
          区域名称:
          <span>{{ vipdetail.areaName }}</span>
        </div>
      </el-form-item>
      <el-form-item label="生日">
        <div>
          <span>{{ vipdetail.birthday }}</span>
        </div>
        <div>
          地址:
          <span>{{ vipdetail.address || "--" }}</span>
        </div>
      </el-form-item>
      <el-form-item label="兴趣">
        <div>
          <span>{{ vipdetail.hobby }}</span>
        </div>
        <div>
          行业:
          <span>{{ vipdetail.industry }}</span>
        </div>
      </el-form-item>
      <el-form-item label="学历">
        <div>
          <span>{{ vipdetail.education }}</span>
        </div>
      </el-form-item>
      <el-form-item label="是否推广员">
        <div>
          <span>{{ vipdetail.isTj }}</span>
        </div>
        <div>
          是否有上级推广员:
          <span>{{ vipdetail.isUp ? '是' : '否' }}</span>
        </div>
      
      </el-form-item>
      <el-form-item label="推广员ID " v-if="vipdetail.isUp">
        <div>
          <span>{{ vipdetail.tjId }}</span>
        </div>
       
        <div>
          推广员手机
          <span>{{ vipdetail.tjPhone  }}</span>
        </div>
      </el-form-item>
      <el-form-item label="推广员头衔" v-if="vipdetail.isUp">

        <div>
          <span>{{ vipdetail.tjDisLevelTitle }}</span>
        </div>
        <div>
          推广员姓名:
          <span>{{ vipdetail.tjName }}</span>
        </div>    
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { number } from "echarts";
import { vipDetail } from "../../api/vip";
export default {
  name: "vipdetail",
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      vipdetail: {},
    };
  },
  created() {
    this.getVipdetail();
    console.log(this.$route.query, "alskdjlaksdjlkasd");
  },
  methods: {
    async getVipdetail() {
      const { data } = await vipDetail({
        userId: Number(this.userId),
      });
      if (data.code == 0) {
        this.vipdetail = data.data;
      } else {
        this.$message.eror(data.msg);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.vipdetail {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form-item {
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__content {
      margin-left: 120px !important;
      display: flex;
      // align-items: center;
      // justify-content: space-between;
      .el-button {
        margin-left: 15px;
      }
      div {
        display: inline-block;
        width: 250px;
      }
      .name {
        margin: 0 8px 0 40px;
        color: #606266;
      }
    }
  }
}
</style>